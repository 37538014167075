"use client"

import type { FC } from "react"
import {
  LocalizationProvider as MuiLocalizationProvider,
  type LocalizationProviderProps,
} from "@mui/x-date-pickers"
import type { Dayjs } from "dayjs"

export { renderTimeViewClock } from "@mui/x-date-pickers"

export { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"

export const LocalizationProvider: FC<
  LocalizationProviderProps<Dayjs, unknown>
> = (props) => <MuiLocalizationProvider {...props} />
